.acc-creation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.acc-creation-heading {
  display: flex;
  justify-content: center;
  font-size: 20px;
  color: #a1947a;
  font-weight: bold;
}

.acc-wrapper {
  width: 50%;
}

.acc-option-container {
  display: flex;
  justify-content: center;
  margin-top: 13px;
}

.acc-option-wrapper {
  display: flex;
  flex-direction: column;
}

.leave-btn {
  background-color: red;
  border: 1px solid red;
  margin-top: 10px;
  cursor: pointer;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 18px;
  padding: 5px;
}

@media (max-width: 550px) {
  .acc-wrapper {
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 750px) {
  .acc-wrapper {
    width: 100%;
    padding: 10px;
  }
}
