.mr-10{
    margin-right: 10px !important;
}
.mr-15{
    margin-right: 15px !important;
}
.mr-20{
    margin-right: 20px !important;
}
.text-color{
    color:#FFFF !important;
}
.header_{
    background: #A1947A !important;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: fixed;
              overflow: scroll;
              z-index: 999;
              width: 100%;
}
.ant-select-selection{
border: none !important;
}

.ant-layout{
    background: white !important;
}
.ant-menu-dark{
    background-color: #FFFF !important;
}

.ant-menu{
    background-color: #FFFF !important;
}

.ant-layout-sider-children{
    background-color: #FFFF !important;
}

.ant-drawer-wrapper-body{
    background-color: #FFFF !important;
}

.ant-menu-dark .ant-menu-item:hover{
    background-color: #d4d4d4 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected{
    background-color: #d4d4d4 !important;

}

.info-message-heading{
    display: flex;
    font-size: 20px;
    justify-content: center;
}
.info-message-login{
    display: flex;
    justify-content: center;
    background: #A1947A;
    border-radius: 50px;
    align-items: center;
    cursor: pointer;
    width: 50%;
    font-weight: bold;
    color: white;
    margin-top: 13px;
    height: 30px;
}
.start_txt{
    text-align: center;
    justify-content: center;
}
.solution-btn-message{
    background: green;
    color: white;
    padding: 2px 6px;
    border-radius: 50px;
    text-align: center;
}
.need-btn-message{
    background: #3B5999;
    color: white;
    padding: 2px 6px;
    border-radius: 50px;
    text-align: center;   
}
.borderbtn{
    border: 3px solid #A1947A;
    border-radius: 30px;
    display: flex;
    align-items: center;
    max-height: 37px;
    padding: 0px 1rem 0px 1.1rem;
}
.st_style{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    justify-content: center;
  }
  .loc_modal .ant-btn-disabled, .ant-btn.disabled, .ant-btn[disabled], 
  .ant-btn-disabled:hover, .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled] .active{
      display: none;
  }
  .popup{
    z-index: 100;
    top: 148px;
    margin: 20px;
    border-radius: 15px;
    background-color: white;
    padding: 10px;
    position: fixed;
  } 
.iblue {
    position:fixed;
    top: 150px;
    width: 0;
    height: 0;
    border-width: 0 40px 20px;
    margin-left: 20px;
    border-style: solid;
    border-color: white transparent white;
}
@media(max-width:370px){
    .popup{
        top: 125px ;
    }
    .iblue{
        top:128px;
    }
}
@media (min-width:500px){
    .popup{
        display: none;
    }
}
.close_pop{
    position: absolute;
    top: 10px !important;
    right: 10px !important;
    color: red !important;
}