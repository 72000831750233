.optionsWrapper{
    display: flex;
    align-items: center;
}
.ant-menu{
    background-color: #001428 !important;
}
.ant-menu-item{
    background: #FFFF;
    color: #A1947A !important;
}

.heading-title{
    font-weight: bold;
    color: white;
    font-size: 19px;
}
.heading2-title{
    font-weight: bold;
    color: white;
    font-size: 19px;
}

.mailto{
    display: flex;
    align-items: center;
    color:#A1947A !important;
}
.mailto:hover{
    color:#A1947A !important;
}

.logo{
    width: 30%;
    height: 30%;
    margin: 6px 0px;
    align-items: center;
    border-radius: 50%;
    -webkit-box-shadow: 0px 2px 4px 1px rgba(161,161,161,1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(161,161,161,1);
    box-shadow: 0px 2px 4px 1px rgba(161,161,161,1);
}
.drawer-logo{
    width: 10%;
    margin: 17px 0px;
    align-items: center;
    margin-left: 20px;
}
.table-responsive{
    width:100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.drawer-wrapper .ant-drawer-content{
    background-color: #001327 !important;
}
.drawer-container{
    display:flex;
    align-items:center;
}
.table-container{
margin-bottom:20px;
}
.search-container{
display: flex; 
justify-content:space-around;
flex-wrap: wrap;
align-items: flex-end;
}
.logo-wrapper{
    display: flex;
    justify-content: center;
}
.logo-text{
    color:#FFFF;
    font-weight: bold;
    font-size: 30px;
}
.ant-drawer-body{
  padding:0 !important;
  margin-top: 24px;
}
.notify-wrapper{
    display:flex;
    /* justify-content:center; */
    align-items:center;
    border-bottom: 1px solid #f1eded;
    height: 70px;
    min-height: 70px;
    padding: 0px 10px;
}
.notify-menu-wrapper{
    width: 100%;
    max-width: 100%;
}
.notify-image{
    width:40px;
    height:40px;
}
.time-selection{
     width: 200px;
}

.sideNav-img{
    width: 20px; 
    margin-right: 10px;
}

.sideNav-link{
    color: #A1947A !important;
    display: flex;
    align-items: center;
}
.language-container{
    position:absolute;
    bottom: 9px;
    right: 12px;
}

.language-container-id{
    position:absolute;
    top: 127vh;
    right: 12px;
}

.ant-modal-content{
    border-radius: 18px !important;
}

.ant-popover-title{
    display: none;
}

.ant-modal-body{
    padding: 12px !important;
}

.menu-container{
    height: 100vh;
    overflow: scroll;
}

.side-nav-center-image{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #CCBEA3;
    padding: 1.5px;
    border: 0.1px solid #ffffff;
}

@media(max-width:750px){
    .search-wrapper{
        width: 100%;
        margin-bottom: 20px;
    }
    .time-selection{
        width: 100%
    }
}

.offer-cart_container{
    background: white;
    height: 20px;
    display: flex;
    width: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
}

@media(max-width:500px){
    .mobile-categories{
        display: none !important;
    }
  .logo-wrapper{
    display: flex;
    margin-top: -30px;
  }

  .offer-cart_container{
    height: 25px;
    width: 25px;
    margin-right: 3px;
}

  .logo{
    width: 40%;
    height: 40%;
    border-radius: 50%;
    margin: 10px 0px;
    -webkit-box-shadow: 0px 2px 4px 1px rgba(161,161,161,1);
    -moz-box-shadow: 0px 2px 4px 1px rgba(161,161,161,1);
    box-shadow: 0px 2px 4px 1px rgba(161,161,161,1);
  }

  .heading-title{
    font-size: 12px;
  }
  .heading2-title{
    font-size: 13px !important;
}
  
}



