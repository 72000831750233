
.mobile-nav{
  display: none; 
}

.mobile-nav::-webkit-scrollbar{
  display: none;
}

@media only screen and (max-width: 499px){
  .fixed-top-navbar{
    position: fixed;
    z-index: 1;
    overflow: auto;
    width: 100%;
    margin-left: -10px;
  }
  .mobile-nav{
    display: block;
    background: rgb(204, 189, 159);
    padding: 5px;
    overflow-y: scroll;
  }

  .mobile-nav-item-text{
    font-size: 10px;
    color: #ffffff;
    font-family: Roboto;
    text-align: center;
  }

  .mobile-nav-image{
    border: 1px solid #ffffff;
    background-position: center;
    background-size: cover;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .mobile-nav-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
  }


  .mobile-nav-image:active{
    background: #A1947A;
  }
  
  .mobile-nav-rows{
    display: flex;
    justify-content: space-between;
    overflow-y: scroll;
    align-items: end;
  }
  
  .mobile-nav-col:focus{
    background: #A1947A;
  }
  
  .mobile-nav-col:visited{
    background: #A1947A;
  }
  .mobile-nav-images{
    width: 0.5px;
  }
}

@media only screen and (max-width: 370px){
  .mobile-nav-image{
    height: 35px;
    width: 35px;
  }
  .ml-28{
    margin-left: 10px !important;
  }
  .mobile-nav-item-text {
    font-size: 8px;
  }
  .mobile-nav-images{
    width: 0.5px;
  }
  .mytxt{
    margin-top: 35px !important;
  }
}
.mytxt{
  margin-top: 50px;
}
.ml-28{
  margin-left:12px;
}
.abs_img{
    top: 0px;
    position: absolute;
}
.fontstyle{
  z-index: 10;
  color: rgb(126, 126, 126);
  position: absolute;
  background-color: transparent;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 30px;
  font-size: xx-large;
}
.left_font{
  left: 0px;
  top:0;
}
.right_font{
  top:0;
  right: 5px;
}
.main_div{
  position: relative;
  align-items: flex-start;
  scroll-behavior: smooth;
}